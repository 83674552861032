import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

var VueScrollTo = require("vue-scrollto");

const app = createApp(App);
app.use(store).use(router).use(VueScrollTo).mount("#app");
router.app = app;
