import { 
  getStatusSampleSpeechSynthesizer,
  generateSampleSpeechSynthesizer,
  listAudioSamples
} from "@/api"
import { SpeechSynthesizerStatus } from "@/enums"


const state = () => ({
  status: null,
  sampleUrl: null,
  sampleCount: 0
})

const mutations = {
  setStatus (state, status) {
    state.status = status
  },
  setSampleURL (state, sampleUrl) {
    state.sampleUrl = sampleUrl
  },
  setSampleCount (state, count) {
    state.sampleCount = count
  }
}

const getters = {
  isGenerated (state) {
    return state.status === SpeechSynthesizerStatus.COMPLETED
  },
  isProcessing (state) {
    return state.status === SpeechSynthesizerStatus.IN_PROGRESS
  },
  isNotStarted (state) {
    return state.status === SpeechSynthesizerStatus.NOT_STARTED || state.status === SpeechSynthesizerStatus.FAILED
  },
  sampleCount (state) {
    return state.sampleCount
  },
  sampleUrl (state) {
    return state.sampleUrl
  }
}

const actions = {
  async status ({ commit }) {
    let response
    try {
      response = await getStatusSampleSpeechSynthesizer()
    } catch (error) {
      console.error(error)
      return
    }
    if (!response) return
    commit("setStatus", response.status)
    commit("setSampleURL", response.voice_file)
  },
  async generate () {
    await generateSampleSpeechSynthesizer()
  },
  async listSamples ({ commit }) {
    let samples
    try {
      samples = await listAudioSamples()
    } catch (error) {
      console.error(error)
      return
    }
    commit("setSampleCount", samples?.count || 0)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
