import axios from "axios";
import Cookies from "js-cookie";
 
const API_BASE_URL = "https://www.baigrapher.com/api/v1";

const _getConfig = (config = {}) => {
  const token = Cookies.get("api-auth-token")
  if (token)  {
    if (!config.headers) config.headers = {}
    config.headers["Authorization"] = `Basic ${token}`
  }
  return config
}

const _get = async (url) => {
  const config = _getConfig()
  const response = await axios.get(`${API_BASE_URL}${url}`, config);
  return response.data;
}

const _post = async (url, params, config) => {
  config = _getConfig(config)
  const response = await axios.post(`${API_BASE_URL}${url}`, params, config);
  return response.data;
}

const _patch = async (url, params) => {
  const config = _getConfig()
  const response = await axios.patch(`${API_BASE_URL}${url}`, params, config);
  return response.data;
}

const _delete = async (url) => {
  const config = _getConfig()
  const response = await axios.delete(`${API_BASE_URL}${url}`, config);
  return response.data;
}

export const createLoginToken = async (username, password) => {
  try {
    const response = await _post("/auth-token", {
      username: username,
      password: password
    });
    return response;
  } catch (error) {
    if (error.code === "ECONNABORTED") {
      try {
        const response = await _post("/auth-token", {
          username: username,
          password: password
        });

        return response;
      } catch (error) {
        console.log(error);
        throw new Error("");
      }
    } else {
      console.log(error);
      throw new Error("");
    }
  }
};

export const createSubject = async (subject, biography) => {
  try {
    const response = await _post(`/biographies/${biography.id}/subjects`, { subject });
    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const patchSubject = async (subject, biography) => {
  try {
    const response = await _patch(`/biographies/${biography.id}`, { subject });
    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const getSubject = async (biography) => {
  try {
    const response = await _get(`/biographies/${biography.id}`);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const createPeople = async (person) => {
  try {
    const response = await _post("/people",
      {
        first_name: person.first_name,
        last_name: person.last_name,
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

//get photos of testimonial
//delete photo
//create photo

export const updatePeople = async (person) => {
  try {
    const response = await _patch(
      `/people/${person.id}`,
      {
        first_name: person.first_name,
        last_name: person.last_name,
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const getPeople = async () => {
  try {
    const response = await _get("/people");
    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const getSpecificPeople = async (personId) => {
  try {
    const response = await _get(`/people/${personId}`);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const createTestimonial = async (testimonial, biography) => {
  try {
    const response = await _post(
      `/biographies/${biography.id}/testimonials`,
      {
        content: testimonial.content,
        date: testimonial.date.split("-")[0] + "-01-01T00:00:00.000Z",
        reference: testimonial.order,
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const patchTestimonial = async (testimonial, biography) => {
  try {
    const response = await _patch(
      `/biographies/${biography.id}/testimonials/${testimonial.id}`,
      {
        content: testimonial.content,
        date: testimonial.date.split("-")[0] + "-01-01T00:00:00.000Z",
        reference: testimonial.order,
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const deleteTestimonial = async (testimonial, biography) => {
  try {
    const response = await _delete(`/biographies/${biography.id}/testimonials/${testimonial.id}`);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const getTestimonial = async (biography) => {
  try {
    const response = await _get(`/biographies/${biography.id}/testimonials`);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const getSpecificTestimonial = async (testimonial, biography) => {
  try {
    const response = await _get(`/biographies/${biography.id}/testimonials/${testimonial.id}`);
    return response
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
}

//get photos of testimonial
export const getTestimonialPictures = async (testimonial, biography) => {
  try {
    const response = await _get(`/biographies/${biography.id}/testimonials/${testimonial.id}/pictures`);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};
//delete photo
export const deleteTestimonialPicture = async (
  pictureId,
  testimonial,
  biography
) => {
  try {
    const response = await _delete(`/biographies/${biography.id}/testimonials/${testimonial.id}/pictures/${pictureId}`);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};
//create photo
export const createTestimonialPicture = async (
  image,
  description,
  testimonialId,
  biography
) => {
  try {
    const response = await _post(
      `/biographies/${biography.id}/testimonials/${testimonialId}/pictures`,
      {
        biography_nested_entity: testimonialId,
        description: description,
        image: image,
      },
      {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${image._boundary}`,
        }
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const getRelationships = async (biography) => {
  try {
    const response = await _get(`/biographies/${biography.id}/relationships`);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const createRelationship = async (
  relationship,
  person,
  biography
) => {
  try {
    const response = await _post(
      `/biographies/${biography.id}/relationships`,
      {
        related_person: person.id, //uuid of person
        relationship_type: relationship, //option provided by be  "FATHER" or "MOTHER"
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const patchRelationship = async (
  relationship,
  person,
  biography
) => {
  try {
    const response = await _patch(
      `/biographies/${biography.id}/relationships/${relationship.id}`,
      {
        relationship: {
          related_person: person.id, //uuid of person
          relationship_type: relationship, //option provided by be  "FATHER" or "MOTHER"
        },
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const deleteRelationship = async (relationshipId, biography) => {
  try {
    const response = await _delete(`/biographies/${biography.id}/relationships/${relationshipId}`);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const generateBook = async (biography) => {
  try {
    const response = await _post(`/biographies/${biography.id}/generate`, {});
    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const getStatus = async (biography) => {
  try {
    const response = await _get(`/biographies/${biography.id}/generation-status`);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const getRelationship = async (biography) => {
  try {
    const response = await _get(`/biographies/${biography.id}/relationships`);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const createBiography = async (biography) => {
  try {
    const response = await _post("/biography-demo", biography);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error(
      "An error ocurred while generating the biography. Error code 100"
    );
  }
};

export const createUBiography = async (subject) => {
  try {
    const response = await _post("/biographies", subject);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const getUBiography = async () => {
  try {
    const response = await _get("/biographies");
    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const getSpecificUBiography = async (biography) => {
  try {
    const response = await _get(`/biographies/${biography.id}`);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const getMe = async () => {
  try {
    const response = await _get("/me");
    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const getPhoto = async (url) => {
  try {
    const response = await _get(url);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error("");
  }
};

export const consultInterestList = async () => {
  try {
    const response = await _get("/interests?limit=100");
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getAudioBook = async (biographyId) => {
  try {
    const response = await _get(`/biographies/${biographyId}/audio-book`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const generateAudioBook = async (biographyId) => {
  try {
    const response = await _post(`/biographies/${biographyId}/audio-book`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getStatusSampleSpeechSynthesizer = async () => {
  try {
    const response = await _get("/speech-synthesizer/generate");
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const generateSampleSpeechSynthesizer = async () => {
  try {
    const response = await _post("/speech-synthesizer/generate");
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const uploadAudioSample = async (blob, filename) => {
  try {
    const formData = new FormData();
    formData.append("file", blob, filename);
    const response = await _post("/speech-synthesizer/audio-samples", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const listAudioSamples = async () => {
  try {
    const response = await _get("/speech-synthesizer/audio-samples");
    return response;
  } catch (error) {
    console.log(error);
  }
};