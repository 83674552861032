<template>
  <div class="home">
    <div id="p1">
      <div id="logo">
        <img src="../assets/img/bAI.png" style="height: 25vw; max-height: 18vh;"/>
      </div>
      <div id="header">
        <svg class="stopper" viewBox="0 0 51 42">
          <use xlink:href="#stopperL" />
        </svg>
        <router-link to="/trial" style="text-decoration: none; color: #ececed; width: 10vh;">
          Demo</router-link
        >
        <div class="divider"></div>
        <router-link to="/signup" style="text-decoration: none; color: #ececed"
          >Patient sign up</router-link
        >
        <svg class="stopper" viewBox="0 0 51 42">
          <use xlink:href="#stopperR" />
        </svg>
      </div>

      <div class="wrapper01">
        <div id="text01">
          <h2>Hi there.</h2>
          <h2 style="color: #ff555b">
            I am the world's 1st Artificial Intelligence biographer_
          </h2>
          <p>
            I help people with Alzheimer's maintain their cognitive function by
            writing their life stories based on recollections from them and their loved
            ones.
          </p>
        </div>
      </div>
      <div  id="arrowDown">
        <svg v-scroll-to="'#p1-1'" viewBox="0 0 64 34">
          <use xlink:href="#arrowD" />
        </svg>
      </div>
    </div>
    <div id="p1-1">
      <div style="padding: 56.25% 0 0 0; position: relative">
        <iframe
          src="https://player.vimeo.com/video/823752671?h=6d49cc0ddc&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          title="bAIgrapher Case Film"
        ></iframe>
      </div>
    </div>
    <div id="p2">
      <div class="wrapper02" id="p2-1">
        <div>
          <div style="transform: scale(1.3)" id="text02">
            <h2 class="quote">“</h2>
            <h2>
              Reminiscence therapy <br />
              is an important tool, <br />
              but it's hard to implement <br />
              and maintain. It's a huge <br />
              burden on the caregiver. <br />
              So, it's not used as often <br />
              as it should be.
            </h2>
            <h2
              class="quote second-quote"
              style="margin-top: 23vh; margin-left: -5vh; position: relative"
            >
              ”
            </h2>
          </div>
          <div style="transform: scale(1.2)" id="img02" class="desktop">
            <img src="../assets/img/CHAPPER.png" />
          </div>
          <div id="img02" class="mobile">
            <img src="../assets/mobile/CHAPPER.png" />
          </div>
        </div>
        <div id="arrowDown">
          <svg v-scroll-to="'#p2-2'" viewBox="0 0 64 34">
            <use xlink:href="#arrowD" />
          </svg>
        </div>
      </div>

      <div id="p2-2" style="padding: 56.25% 0 0 0; position: relative">
        <iframe
          src="https://player.vimeo.com/video/823754448?h=fb7d97e9e5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          title="bAIgrapher Tutorial"
        ></iframe>
      </div>

      <div id="arrowDown" style="margin-top: 0vh;">
        <svg v-scroll-to="'#p2-3'" viewBox="0 0 64 34">
          <use xlink:href="#arrowD" />
        </svg>
      </div>

      <div class="wrapper02" id="p2-3">
        <div>
          <div style="transform: scale(1.3)" id="text02">
            <h2 class="quote">“</h2>
            <h2>
              bAIgrapher has a <br />
              tremendous potential <br />
              to help thousands, if not <br />
              millions of patients with <br />
              Alzheimer's. It is <br />
              accessible and is based <br />
              on solid science.
            </h2>
            <h2
              class="quote second-quote"
              style="margin-top: 23vh; margin-left: -5vh; position: relative"
            >
              ”
            </h2>
          </div>
          <div style="transform: scale(1.25)" id="img04" class="desktop">
            <img src="../assets/img/ANDREANSKY.png" style="height: 55vh; width: auto;"/>
          </div>
          <div id="img04" class="mobile">
            <img src="../assets/mobile/ANDREANSKY.png" />
          </div>
        </div>
        <div id="arrowDown">
          <svg v-scroll-to="'#p2-4'" viewBox="0 0 64 34">
            <use xlink:href="#arrowD" />
          </svg>
        </div>
      </div>

      <div class="wrapper02" id="p2-4">
        <div style="display: block">
          <div id="title04" class="desktop">
            <h2>How I do it_</h2>
            <div id="timeline" class="desktop">
              <img style="margin-top: 4vh" src="../assets/img/HOW_i_DO_IT.png" />
            </div>
          </div>
          
          <div id="timeline02" class="desktop">
            <img
              class="gradient"
              src="../assets/img/GRADIENT.png"
              style="margin-bottom: -43%"
            />
            <img
              src="../assets/img/TIMELINE.png"
              style="
                mix-blend-mode: screen;
                mix-blend-mode: screen;
                width: 90vw;
                height: auto;
              "
            />
          </div>
          <div id="N1" class="mobile" style="height: 350vw;">

          </div>
        </div>
        <div id="arrowDown" style="margin-top: 0;">
          <svg v-scroll-to="'#p2-5'" viewBox="0 0 64 34">
            <use xlink:href="#arrowD" />
          </svg>
        </div>
      </div>

      <div class="wrapper02" id="p2-5">
        <div style="display: flex; align-items: end; height: 100vh">
          <div id="img06" class="desktop">
            <img
              class="flare"
              src="../assets/img/FLARE02.png"
              style="mix-blend-mode: screen"
            />
            <img class="hand" src="../assets/img/SOCIALS.png" />
          </div>
          <div id="text06">
            <p class="desktop">
              Patients and loved ones receive a beautifully printed <br>
              biography and an audiobook, with the patient's <br />
              own synthesized voice narrating their story. <br />
              Therapy for patients, legacy for loved ones.
            </p>
            <p class="mobile">
              Patients and loved ones receive a beautifully printed
              biography and an audiobook, with the patient's
              own synthesized voice narrating their story.
              Therapy for patients, legacy for loved ones.
            </p>
            <div class="selectWrapper">
              <svg viewBox="0 0 157 145">
                <use xlink:href="#diamondSelect" />
              </svg>
              <div class="select">
                <div>
                  <router-link to="/trial">
                    <p>Demo</p>
                  </router-link>
                </div>
              </div>
            </div>
            <div style="margin-top: 3vh" class="selectWrapper">
              <svg viewBox="0 0 157 145">
                <use xlink:href="#diamondSelect" />
              </svg>
              <div class="select">
                <router-link to="/signup">
                  <div style="padding-top: 4vh; padding-left: 4.5vh" class="patient">
                    <p>Patient<br />Sign up</p>
                  </div>
                </router-link>
              </div>
            </div>
            <div style="margin-top: 3vh" class="selectWrapper">
              <svg viewBox="0 0 157 145">
                <use xlink:href="#diamondSelect" />
              </svg>
              <div class="select">
                <div>
                  <router-link to="/audiobook">
                    <p>Audiobook</p>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="p3">
      <div id="arrowDown" style="margin-top: 0; padding-top: 5vh; display: flex; height: auto;">
          <svg v-scroll-to="'#p3-1'" viewBox="0 0 64 34">
            <use xlink:href="#arrowD" />
          </svg>
        </div>
      <div id="p3-1">
        <div id="text">
          <h2>
            UFCSPA, <br />
            one of the top <br />
            medical <br />
            universities <br />
            in Brazil
          </h2>
          <p>
            sees great potential and is <br />
            planning to make bAIgrapher <br />
            part of their Alzheimer's <br />
            treatment protocol.
          </p>
        </div>
        <div id="img03" class="desktop">
          <img src="../assets/img/UFCSPA.png" />
        </div>
        <div id="img03" class="mobile">
          <img src="../assets/mobile/UFCSPA.png" />
        </div>
      </div>

      <div id="logos" class="desktop">
        <img src="../assets/img/LOGOS.png" />
      </div>
      <div id="logos" class="mobile">
        <img src="../assets/mobile/LOGOS.png" />
      </div>
      <div id="footer">
        <div></div>
        <router-link
          to="/privacy-policy"
          style="text-decoration: none; color: #ececed"
        >
          Privacy Policy
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  font-family: "Playfair Display", serif;
  margin: 0;
}
.mobile {
  display: none !important;
}
#p1 {
  height: 100vh;
  background-image: url("../assets/bg/01.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  overflow: hidden;
}
#logo {
  position: absolute;
  left: 5vh;
  top: -3vh;
}
#header {
  display: flex;
  justify-content: flex-end;
  margin-top: 5vh;
  margin-right: -2vh;

  font-size: 2.5vh;
  color: #ececed;
  height: 5vh;
}
#header a {
  padding: 0 2vh;
  width: 18vh;
  text-align: center;
  margin: auto 0;

  cursor: pointer;
}
#header a:hover {
  color: #e04043;
}
.divider {
  height: 5vh;
  border: solid 1px #ececed;
  margin: auto 0;
}
.stopper {
  height: 5vh;
  margin: auto 0;
}

.wrapper01 {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

#text01 {
  width: 55vh;
  padding-left: 14vh;
  padding-top: 12vh;
  color: #ffffff;
}

#text01 h2 {
  display: table;
  margin: 0;

  font-size: 7vh;
  font-weight: bold;
  line-height: 1;

  mix-blend-mode: screen;
}

#text01 p {
  margin-top: 8vh;
  font-size: 2.3vh;
  width: 40vh;
  letter-spacing: -0.04vh;
}

.video01 {
  width: 0 auto;
  margin: 0 auto;
  display: flex;
  z-index: 1;
}
.video01 p {
  cursor: pointer;
  color: #e04043;
  font-size: 3.4vh;
  font-weight: bold;
  mix-blend-mode: lighten;
}
.video01 svg {
  cursor: pointer;
  height: 5vh;
  margin-right: 1vh;
}

#arrowDown {
  display: flex;
  margin-top: 10vh;
  height: 12vh;
}
#arrowDown svg {
  margin: auto;
  height: 4vh;
  transition: ease-in 0.2s;
}
#arrowDown svg:hover {
  transform: scale(1.1);
}

#p2 {
  background-image: url("../assets/bg/02.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.wrapper02 {
  height: 100vh;
}

.wrapper02 div {
  display: flex;
  justify-content: center;
}

#text02 {
  display: flex;
  padding-top: 15vh;
}
#text02 h2 {
  font-size: 3.4vh;
  color: #ffffff;
  line-height: 1.2;
  font-weight: 500;
  overflow: visible;
  height: fit-content;
}
#text02 .quote {
  color: #ff555b;
  font-size: 9vh;
  margin-top: -7%;
}

#img02 {
  display: flex;
  overflow: hidden;
  height: 77vh;
}

#img02 img {
  height: 65vh;
  margin-top: 6.5vh;
}
#img03 {
  display: contents;
  padding-top: 10vh;
}
#img03 img {
  height: 60vh;
}
#img03 .flare {
  mix-blend-mode: screen;
  position: relative;
}
#img03 .book {
  margin-left: -70vh;
  margin-top: 10vh;
  margin-right: 10vh;
}

.text03 {
  display: block;
  margin-top: 0vh;
  padding-right: 10vh;
}
.text03 h3 {
  font-size: 3.4vh;
  color: #ff555b;
  line-height: 1.1;
  letter-spacing: -0.1vh;
  overflow: visible;
  height: fit-content;
}
#video03 {
  margin-top: 2vh;
  justify-content: left;
  margin-left: 5vh;
  margin-bottom: 1vh;
}
#video03 svg {
  height: 5vh;
  cursor: pointer;
}
#video03 p {
  color: #ffffff;
  font-size: 3vh;
  line-height: 4.5vh;
  cursor: pointer;
  margin-left: 1vh;
}
#title04 {
  font-size: 3vh;
  color: #e04043;
  padding-top: 10vh;
  display: block;
  width: fit-content;
  margin: 0 auto;
}
#title04 
#img04 {
  margin-top: 10vh;
}
#img04 img,
#timeline02 img {
  width: 80vw;
  margin-bottom: 12vh;
  margin-top: 0vh;
  max-width: 70em;
}
#img04 img{
  margin-top: 5vh;
  max-width: 80vw;
}
#timeline {
  overflow: hidden;
  justify-content: center;
}
#timeline img {
  width: 90vw;
  max-width: 50em;
}
#timeline02 {
  display: grid;
  margin-bottom: 2vh;   
  margin-top: -5vh;
}
#timeline02 img {
  margin-bottom: 0;
}
#img06 {
  align-items: end;
  max-width: 70vw;
}
#img06 .flare {
  height: 80vh;
}
#img06 .hand {
  height: 70vh;
  margin-left: -83vh;
  z-index: 99;
}
#img06 .arrow {
  width: 100vh;
  z-index: 100;
  padding-bottom: 20vh;
}
#text06 {
  display: block;
  text-align: center;
  color: #ffffff;
  padding-bottom: 12vh;
  margin-left: -20vh;
}
#text06 a {
  color: #ffffff;
  text-decoration: none;
}
#text06 p {
  font-size: 2.2vh;
  margin-bottom: 10vh;
  letter-spacing: -1px;
}
#text06 svg {
  height: 18vh;
}

#text06 .select {
  transform: rotate(45deg);
  height: 12vh;
  width: 12vh;
  margin-left: -15.8vh;
  margin-top: 3vh;
  cursor: pointer;
}
#text06 .select:hover {
  background-color: rgb(224, 64, 67, 0.8);
  mix-blend-mode: screen;
}
#text06 .select div {
  transform: rotate(-45deg);
  padding-top: 4.3vh;
}
#p3 {
  justify-content: center;
  background-image: url("../assets/bg/03.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
#p3 div {
  display: flex;
  justify-content: center;
}
#p3 #text {
  padding-top: 15vh;
  font-size: 3vh;
  color: #e04043;
  line-height: 1.1;
  letter-spacing: -0.1vh;
  overflow: visible;
  height: fit-content;
  display: block;
}
#p3 #img03 {
  padding-top: 5vh;
}
#p3 p {
  color: #ffffff;
  margin-top: 2vh;
  font-size: 2.4vh;
  line-height: normal;
}
#logos img {
  width: 90vw;
  margin-top: 15vh;
}
#p3 #footer {
  display: block;
  text-align: center;
  height: 10vh; 
  position: relative;
  width: 100%;
  bottom: 0;
}
#footer div {
  background-color: rgb(224, 64, 67, 0.8);
  mix-blend-mode: multiply;
  height: 10vh;
  margin-top: 6vh;
  width: 100%;
  margin-bottom: -6.5vh;
}
#footer a {
  color: #ffffff;
  position: relative;
  width: 100vh;
  font-size: 2.4vh;
  cursor: pointer;
  padding: 1vh 2vh;
  border-left: solid 1px white;
  border-right: solid 1px white;
}

@media screen and (max-width: 750px) {
  .mobile {
    display: grid !important;
  }
  .desktop{
    display: none!important;
  }
  #logo {
    top: -2vh;
    left: 1vh;
  }
  #logo img {
    height: 25vw!important;
    min-height: 10vh;
  }
  #p1 {
    background-image: url("../assets/mobile/01.png");
    height: fit-content;
    background-position: center top;
  }
  #text01 {
    padding-left: 10vw;
    padding-top: 42vh;
  }
  #text01 h2 {
    mix-blend-mode: normal;
    font-size: 6vh;
    width: 80vw;
  }
  #text01 p {
    margin-top: 3vh;
    margin-bottom: 3vh;
    width: 70vw;
  }
  #header{
    margin-top: 2vh;
    margin-right: -4.5vh;
  }
  #header svg, #header .divider{
    height: 3.5vh;
  }
  #header a {
    width: 13vh;
    padding: 0 1vh;
    font-size: 1.8vh;
  }
  #p1 .wrapper01 {
    display: block;
  }
  #arrowDown {
    margin-top: 5vh;
  }
  #p2 {
    overflow-x: hidden;
    background-image: url("../assets/mobile/02.png");
    height: fit-content;
    background-position: center bottom;
    padding-bottom: 70vw;
  }

  .wrapper02 {
    height: fit-content;
  }
  .wrapper02 div {
    display: block;
  }
  #text02 {
    padding-top: 7vh;
    padding-left: 5vw;
    justify-content: start;
    transform: none!important;
  }
  #text02 h2{
    font-size: 6.5vw;
  }
  #text02 .quote{
    margin-right: 1vh;
  }
  #text02 .second-quote{
    margin-top: 45vw!important;
  }
  #img02,
  #img04 {
    justify-content: end;
    height: fit-content;
  }
  #img02 img,
  #img04 img {
    width: 100vw;
    max-width: 100vw;
    height: auto;
    margin-top: 0;
    transform: none!important;
  }
  #p2-1{
    height: 243vw;
  }
  #p2-1 #arrowDown {
    margin-top: 0;
    z-index: 999999;
    position: relative;
  }

  #p2-2 div {
    padding-top: 0vh !important;
  }
  #img03 .flare {
    height: 50vh;
    margin-top: -5vh;
  }
  #img03 .book {
    height: 50vh;
    margin-top: -40vh;
    margin-left: 5vh;
  }
  #text03 {
    text-align: center;
    margin-top: 5vh;
    padding-right: 0;
  }
  #text03 h3 {
    font-size: 3vh;
  }
  #video03 {
    display: flex;
  }
  #p2-2 #arrowDown {
    margin-top: 1vh;
  }
  #img04 {
    margin-top: 2vh;
  }
  #img04 img {
    margin: auto 0;
  }
  #p2-3{
    height: 340vw;
  }
  #p2-3 #text02{
    padding-top: 5vh;
  }
  #p2-3 #arrowDown {
    margin-top: 3vh;
  }
  #p3{
    height: fit-content;
    padding-bottom: 3.5vh;
    background-image: url("../assets/mobile/03.png");
    background-position: center bottom;
  }
  #p3 div {
    display: block;
  }
  #p3 #text {
    margin-left: 10vh;
    padding-top: 7vh;
    font-size: 7vw;
  }
  #p3 p{
    font-size: 5vw;
  }
  #img03 {
    text-align: center;
    display: block;
  }
  #img03 img {
    width: 100vw;
    height: auto;
    margin-top: -40vw;
    margin-bottom: 5vh;
  }
  #logos {
    text-align: center;
  }
  #logos img {
    margin-top: 2vh;
    width: 90vw;
    margin-bottom: 5vh;
  }
  #footer div {
    margin-top: -0.1vh;
    width: 100%;
  }
  #title04{
    padding-left: 10vw;
    font-size: 7vw;
  }
  #N1, #N2, #N3, #N4{
    padding: 10% 10%;
    font-size: 4vw;
  }
  #p2-4 #arrowDown{
    margin-top: 0;
  }
  #p2-5 {
    display: block;
    text-align: center;
    margin-top: -5vh;
    height: 220vw;
  }
  #p2-5 .select .patient{
    padding-left: 1.8vh!important;
    padding-top: 3vh!important;
  }
  #p2-5 div {
    display: flex;
  }
  #p2-5 #arrowDown{
    margin-top: -10vh;
  }
  #p2-5 .selectWrapper{
    margin-left: -5vh;
  }
  #img06 {
    display: none !important;
  }
  #text06 {
    display: block !important;
    margin: auto;
    width: 80vw;
  }
  #text06 p{
    font-size: 2.4vh;
    margin-bottom: 10vw;
  }
  #text06 .mobile{
    width: 70vw;
    margin-left: 7vw;
  }
}
</style>

<script>
// @ is an alias to /src

export default {
  name: "bAIgrapher",
  data() {
    return {
      isVideoShowing: false,
      isVideoShowing2: false,
    };
  },
  computed: {
    calcHeight() {
      return window.innerHeight;
    },
    calcWidth() {
      return window.innerWidth;
    },
  },
  methods: {
    showVideo() {
      this.isVideoShowing = !this.isVideoShowing;
    },
    closeVideo() {
      this.isVideoShowing = false;
    },
    showVideo2() {
      this.isVideoShowing2 = !this.isVideoShowing2;
    },
  },
};
</script>
