import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import Cookies from "js-cookie";

const routes = [
  {
    path: "/",
    name: "bAIgrapher",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/questions",
    name: "bAIgrapher ",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/QuestionView.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SignupView.vue"),
  },
  {
    path: "/trial",
    name: "trial",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TrialView.vue"),
  },
  {
    path: "/biography",
    name: "biography",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BiographyView.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PrivacyPolicyView.vue"),
  },
  {
    path: "/form",
    name: "form",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FormView.vue"),
  },
  {
    path: "/audiobook",
    name: "audiobook",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AudiobookView.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/disclaimer-app",
    name: "disclaimer-bAIgrapher",
    component: () => import("../views/DisclaimerHomeView.vue"),
  },
  {
    path: "/loading",
    name: "loading-bAIgrapher-app",
    component: () => import("../views/LoadingView.vue"),
  },
  {
    path: "/loading-form",
    name: "loading-bAIgrapher-app  ",
    component: () => import("../views/LoadingFormView.vue"),
  },
  {
    path: "/loading-book",
    name: "loading-bAIgrapher-app ",
    component: () => import("../views/LoadingBookView.vue"),
  },
  {
    path: "/disclaimer-questions",
    name: "disclaimer-bAIgrapher ",
    component: () => import("../views/DisclaimerFormView.vue"),
  },
  {
    path: '/disclaimer-testimonials',
    name: 'disclaimer-testimonials',
    component: () => import("../views/DisclaimerTestimonialView.vue")
  },
  {
    path: "/writing-book",
    name: "bAIgrapher-writing",
    component: () => import("../views/BookUnderConstructionView.vue"),
  },
  {
    path: "/book",
    name: "book",
    component: () => import("../views/BookReadView.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/RegisterView.vue"),
  },
  {
    path: "/app-home",
    name: "app-home",
    component: () => import("../views/AppHome.vue"),
  },
  {
    path: "/more-testimonials",
    name: "more-testimonials",
    component: () => import("../views/MoreTestimonialsView.vue"),
  },
  {
    path: "/speech-synthesizer/samples",
    name: "speech-synthesizer-samples",
    component: () => import("../views/AudioSamplesRecorderView.vue"),
  },
  {
    path: "/speech-synthesizer/generate",
    name: "speech-synthesizer-generate",
    component: () => import("../views/AudioSynthesizerGenerateView.vue"),
  },
  {
    path: "/speech-synthesizer/preview",
    name: "speech-synthesizer-preview",
    component: () => import("../views/AudioSynthesizerPreviewView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  let isAuthenticated = !!Cookies.get("api-auth-token")

  if (isAuthenticated) {
    const nextIsLogin = to.path === "/login"
    const userDataLoaded = !!from.name // TODO: Aqui tem que pegar o `me` da store pra funcionar 100%
    const shouldSendToLoading = !userDataLoaded && to.name !== "loading-bAIgrapher-app"
    const nextIsHome = to.path === "/"
    if ((nextIsLogin || shouldSendToLoading) && !nextIsHome)
      next({ name: "loading-bAIgrapher-app" });
    else next()

    return
  }

  let requiresAuth = [
    "/disclaimer-app",
    "/app-home",
    "/book",
    "/writing-book",
    "/disclaimer-questions",
    "/loading-book",
    "/loading",
    "/loading-form",
    "/form",
    "/more-testimonials",
    "/speech-synthesizer/samples",
    "/speech-synthesizer/generate",
    "/speech-synthesizer/preview"
  ];
  if (requiresAuth.includes(to.path))
    next({ name: "Login" });
  else next();
});

export default router;
