import { getAudioBook, generateAudioBook } from "@/api"
import { AudioBookStatus } from "@/enums"


const state = () => ({
  loaded: false,
  status: null,
  audioUrl: null
})

const mutations = {
  setAudioBook (state, audioBook) {
    state.status = audioBook.status
    state.audioUrl = audioBook.file
  },
  setLoaded (state, loaded) {
    state.loaded = loaded
  }
}

const getters = {
  isGenerated (state) {
    return state.status === AudioBookStatus.COMPLETED
  },
  isProcessing (state) {
    return state.status === AudioBookStatus.IN_PROGRESS
  },
  isNotStarted (state) {
    return state.status === AudioBookStatus.NOT_STARTED || state.status === AudioBookStatus.FAILED
  },
  isLoaded (state) {
    return state.loaded
  },
  audioUrl (state) {
    return state.audioUrl
  }
}

const actions = {
  async load ({ commit }, biographyId) {
    commit("setLoaded", false)
    let audioBook
    try {
      audioBook = await getAudioBook(biographyId)
    } catch (error) {
      console.error(error)
      return
    }
    commit("setAudioBook", audioBook)
    commit("setLoaded", true)
  },
  async generate (ctx, biographyId) {
    await generateAudioBook(biographyId)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
