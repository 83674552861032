import Cookies from "js-cookie";
import { createStore } from "vuex";
import { createLoginToken } from "@/api";
import audioBookStore from "@/store/audio-book";
import speechSynthesizerStore from "@/store/speech-synthesizer";

//uBio refers to the non patient user biography different than the demo biography
//where even the "FullBiography" variable is part a of the demo
// because it does not have the suffix uBio.
export default createStore({
  state: {
    email: {},
    biography: {},
    fullBiography: {},
    uBioSubject: {},
    uBioEducation: [],
    uBioJobs: [],
    uBioTestimonials: [],
    uBioPeople: [],
    uBioMother: {},
    uBioFather: {},
    me: {},
    fullyLoaded: false,
  },
  mutations: {
    setMe(state, me) {
      state.me = me;
    },
    setEmail(state, email) {
      state.email = email;
    },
    setBiography(state, biography) {
      state.biography = biography;
    },
    setFullBiography(state, fullBiography) {
      state.fullBiography = fullBiography;
    },
    setUBioSubject(state, uBioSubject) {
      state.uBioSubject = uBioSubject;
    },
    setUBioEducation(state, uBioEducation) {
      state.uBioEducation = uBioEducation;
    },
    setUBioJobs(state, uBioJobs) {
      state.uBioJobs = uBioJobs;
    },
    setUBioTestimonials(state, uBioTestimonials) {
      state.uBioTestimonials = uBioTestimonials;
    },
    setBook(state, book) {
      state.book = book;
    },
    setUBioPeople(state, people) {
      state.uBioPeople = people;
    },
    setUBioFather(state, person) {
      state.uBioFather = person;
    },
    setUBioMother(state, person) {
      state.uBioMother = person;
    },
    setFullyLoaded(state, fullyLoaded) {
      state.fullyLoaded = fullyLoaded;
    },
  },
  actions: {
    // eslint-disable-next-line
    async updateLoginToken({ commit }, { user, password }) {
      // Cookie utilizado para acesso às imagens
      const response = await createLoginToken(user, password);
      const imageToken = response.token;
      Cookies.set("auth-token", imageToken, { sameSite: 'Lax' });

      // TODO: Isso aqui errado, o token enviado é um gerado no cliente e não o retornado no servidor
      // Deveria ser usado o mesmo token
      const token = btoa(`${user}:${password}`)
      Cookies.set("api-auth-token", token);
    },
    updateMe({ commit }, me) {
      commit("setMe", me);
    },
    updateEmail({ commit }, email) {
      commit("setEmail", email);
    },
    updateBiography({ commit }, biography) {
      commit("setBiography", biography);
    },
    updateFullBiography({ commit }, fullBiography) {
      commit("setFullBiography", fullBiography);
    },
    updateUBioSubject({ commit }, uBioSubject) {
      commit("setUBioSubject", uBioSubject);
    },
    updateUBioEducation({ commit }, uBioEducation) {
      commit("setUBioEducation", uBioEducation);
    },
    updateUBioJobs({ commit }, uBioJobs) {
      commit("setUBioJobs", uBioJobs);
    },
    updateBook({ commit }, book) {
      commit("setBook", book);
    },
    updateUBioPeople({ commit }, uBioPeople) {
      commit("setUBioPeople", uBioPeople);
    },
    updateUBioTestimonials({ commit }, uBioTestimonials) {
      commit("setUBioTestimonials", uBioTestimonials);
    },
    updateUBioFather({ commit }, uBioFather) {
      commit("setUBioFather", uBioFather);
    },
    updateUBioMother({ commit }, uBioMother) {
      commit("setUBioMother", uBioMother);
    },
    updateFullyLoaded({ commit }, fullyLoaded) {
      commit("setFullyLoaded", fullyLoaded);
    },
  },
  getters: {
    email: (state) => state.email, //string, email format
    biography: (state) => state.biography, //object
    fullBiography: (state) => state.fullBiography, //list of chapters
    book: (state) => state.book || {}, //list of chapters
    uBioSubject: (state) => state.uBioSubject, //object
    uBioEducation: (state) => state.uBioEducation, //list
    uBioJobs: (state) => state.uBioJobs, //list
    uBioTestimonials: (state) => state.uBioTestimonials, //list
    uBioPeople: (state) => state.uBioPeople, //list
    uBioFather: (state) => state.uBioFather, // object
    uBioMother: (state) => state.uBioMother,
    me: (state) => state.me,
    fullyLoaded: (state) => state.fullyLoaded,
  },
  modules: {
    audioBook: audioBookStore,
    speechSynthesizer: speechSynthesizerStore
  }
});
