<template>
  <router-view/>
</template>

<style>

body{
    margin: 0;
  }
</style>
